* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  scrollbar-width: thin;
  scrollbar-color: rgba(16, 20, 23, 0.32), #FAFAFA;
}
body[data-mode="dark"] {
  background-color: var(--lt-color-black);
}
body[data-mode="light"] {
  background-color: var(--lt-color-light);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance:textfield;
}

a {
  text-decoration: none;
}

#root {
  display: flex;
  flex-flow: column;
  min-height: 100vh;
}

img, svg {
  vertical-align: middle;
}

/* Customize website's scrollbar like Mac OS */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar:horizontal {
  height: 8px;
}

::-webkit-scrollbar-track {
  background-color: rgba(16, 20, 23, 0.12);
}

::-webkit-scrollbar-thumb {
  background-color: rgba(16, 20, 23, 0.32);
  border-radius: 8px;
}

::-webkit-scrollbar-button {
  display: none;
}

body.withDrawerOpen::-webkit-scrollbar {
  width: 0;
}

/* Printing rules */
@media screen {
  #printSection {
    display: none;
  }
}

@media print {
  body * {
    visibility: hidden;
  }

  #root {
    display: none;
  }

  .print-block {
    width: 100vw;
  }
  .no-print {
    display: none !important;
  }
  #printSection, #printSection * {
    visibility: visible;
  }
  #printSection {
    position: absolute;
    left: 0;
    top: 0;
  }
}

@media print and (min-width: 960px) {
  @page {
    size: 420mm 594mm;
  }
}
